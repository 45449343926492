export const en = {
    shop: {
        'public.shop.pointsCurrency': 'Points',
    },
    referral: {
        'public.referral.refereeMessage': '',
        'public.referral.shareMessage': '',
    },
    promotion: {
        'public.promotion.title': '',
        'public.promotion.description': '',
    },
    'earn-rule': {
        'public.earnRule.title': 'Earn {{pointsCurrency}}',
        'public.earnRule.subtitle': "Here's how to earn {{pointsCurrency}}",
        'public.earnRule.type.CREATE_ACCOUNT.title': 'Create an account and earn {{points}} {{pointsCurrency}}',
        'public.earnRule.type.PLACE_ORDER.title': 'For every 1€ spent, earn 1 {{pointsCurrency}}',
        'public.earnRule.type.CELEBRATE_BIRTHDAY.title': 'On your birthday you earn {{points}} {{pointsCurrency}}',
        'public.earnRule.type.FOLLOW_ON_INSTAGRAM.title':
            'Follow us on Instagram {{handle}} and earn {{points}} {{pointsCurrency}}',
        'public.earnRule.type.LIKE_ON_FACEBOOK.title':
            'Like our page on Facebook {{handle}} and earn {{points}} {{pointsCurrency}}',
        'public.earnRule.type.SHARE_ON_FACEBOOK.title':
            'Share our shop on Facebook {{handle}} and earn {{points}} {{pointsCurrency}}',
        'public.earnRule.type.FOLLOW_ON_TWITTER.title': 'Follow us on X {{handle}} and earn {{points}} {{pointsCurrency}}',
        'public.earnRule.type.FOLLOW_ON_TIKTOK.title': 'Follow us on TikTok {{handle}} and earn {{points}} {{pointsCurrency}}',
        'public.earnRule.type.SIGNUP_NEWSLETTER.title': 'Subscribe to our newsletter and earn {{points}} {{pointsCurrency}}',
        'public.earnRule.type.ADD_REVIEW.title': 'Add a review and earn {{points}} {{pointsCurrency}}',
    },
    'spend-rule': {
        'public.spendRule.type.FREE_SHIPPING.title': 'Get free shipping on your next order',
        'public.spendRule.type.FREE_SHIPPING.fulfillment': 'Free shipping',
        'public.spendRule.type.FREE_SHIPPING.instructions': 'Use this discount code on your next order!',
        'public.spendRule.type.PRODUCT_DISCOUNT.title': 'Redeem {{points}} {{pointsCurrency}} and get {{discount}} off',
        'public.spendRule.type.PRODUCT_DISCOUNT.fulfillment': '{{discount}} off coupon',
        'public.spendRule.type.PRODUCT_DISCOUNT.instructions': 'Use this discount code on your next order!',
        'public.spendRule.type.ORDER_DISCOUNT.title': 'Redeem {{points}} {{pointsCurrency}} and get {{discount}} off your order',
        'public.spendRule.type.ORDER_DISCOUNT.fulfillment': '{{discount}} off coupon',
        'public.spendRule.type.ORDER_DISCOUNT.instructions': 'Use this discount code on your next order!',
        'public.spendRule.type.POS_DISCOUNT.title': 'Redeem {{points}} {{pointsCurrency}} and get {{discount}} off your order',
        'public.spendRule.type.POS_DISCOUNT.fulfillment': '{{discount}} off coupon',
        'public.spendRule.type.POS_DISCOUNT.instructions': 'POS discount, use in-store!',
        'public.spendRule.type.FREE_PRODUCT.title': 'Redeem {{points}} {{pointsCurrency}} and get a free product',
        'public.spendRule.type.FREE_PRODUCT.fulfillment': 'Free product',
        'public.spendRule.type.FREE_PRODUCT.instructions': 'Use this discount code on your next order!',
        'public.spendRule.type.STORE_CREDIT.title': 'Redeem {{points}} {{pointsCurrency}} and get {{discount}} store credit',
        'public.spendRule.type.STORE_CREDIT.fulfillment': '{{discount}} store credit',
        'public.spendRule.type.STORE_CREDIT.instructions': 'Use this store credit online at checkout on your next order!',
        'public.spendRule.type.GIFT_CARD.title': 'Redeem {{points}} {{pointsCurrency}} for a {{discount}} gift card',
        'public.spendRule.type.GIFT_CARD.fulfillment': '{{discount}} gift card',
        'public.spendRule.type.GIFT_CARD.instructions': "Check your email – we've sent you the gift card code!",
    },
    branding: {
        'public.widget.authenticated.header.title': 'Loyalty & Rewards',
        'public.widget.unauthenticated.header.title': 'Hey {{customerName}}!',
        'public.widget.becomeAMember.title': 'Become a Member',
        'public.widget.becomeAMember.subtitle': 'Join our loyalty program and start earning rewards today.',
        'public.widget.becomeAMember.joinProgram': 'Join Program',
        'public.widget.becomeAMember.alreadyAMember': 'Already a member ?',
        'public.widget.becomeAMember.login': 'Login',
        'public.widget.balanceCard.points': '{{pointsCurrency}}',
        'public.widget.balanceCard.referrals': 'Referrals',
        'public.widget.balanceCard.tier': 'Tier',
        'public.widget.spendAndEarnCard.title': 'Rewards',
        'public.widget.spendAndEarnCard.subtitle':
            'Collect {{pointsCurrency}}, unlock rewards – your shopping just got more exciting!',
        'public.widget.spendAndEarnCard.earn.title': 'Ways to earn {{pointsCurrency}}',
        'public.widget.spendAndEarnCard.spend.title': 'Ways to spend {{pointsCurrency}}',
        'public.widget.spendAndEarnCard.vouchers.title': 'View your coupons',
        'public.widget.referralPage.title': 'Refer a friend',
        'public.widget.activityCard.title': 'Activity',
        'public.widget.referralCard.title': 'Invite your friends and earn rewards',
        'public.widget.tiersPage.title': 'Tiers',
        'public.widget.tiersPage.subtitle': 'Unlock more rewards as you move up the tiers',
        'public.widget.vouchersPage.title': 'Your coupons',
        'public.widget.vouchersPage.subtitle': 'Check out your available coupons',
        'public.widget.rewardsPage.title': 'Rewards',
        'public.widget.rewardsPage.subtitle': `Here's how to spend your hard-earned {{pointsCurrency}}`,
        'public.widget.earnPointsPage.title': 'Earn {{pointsCurrency}}',
        'public.widget.earnPointsPage.subtitle': "Here's how to earn {{pointsCurrency}}",
        'public.widget.activityPage.title': 'Activity',
        'public.widget.activityPage.subtitle': 'Check out your recent activity',
        'public.widget.activityPage.noActivity.title': 'No activity yet',
        'public.widget.rewardsCard.title': 'Rewards',
        'public.widget.rewardsCard.subtitle':
            'Collect {{pointsCurrency}}, unlock rewards – your shopping just got more exciting!',
        // Full page
        'public.fullPage.unauthenticatedHeroTitle': `Join Loyalty Program and get rewarded while you shop. You'll get {{points}} {{pointsCurrency}} for signing up`,
        'public.fullPage.authenticatedHeroTitle': `You have {{points}} {{pointsCurrency}}`,
        'public.fullPage.hero.joinProgram': 'Join Program',
        'public.fullPage.hero.login': 'Login',
        'public.fullPage.navigation.earnPoints': 'Earn {{pointsCurrency}}',
        'public.fullPage.navigation.rewards': 'Rewards',
        'public.fullPage.navigation.tiers': 'Tiers',
        'public.fullPage.navigation.referralProgram': 'Refer a friend',
        'public.fullPage.navigation.activity': 'Your activity',
        'public.fullPage.navigation.storeCredit': 'Store credit {{credit}}',
        'public.fullPage.navigation.vouchers': 'Your coupons',
        'public.fullPage.referralCard.title': 'Refer a friend',
        // Referral Modal
        'public.referrals.title': 'We have a discount for you',
        'public.referrals.subtitle': 'Get 50% off on your first purchase!',
        'public.referrals.label': 'Enter your email bellow and we will send you the discount code.',
        'public.referrals.button': 'Get discount',
    },
};
