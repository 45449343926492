import { resources } from './locales';

export const i18n = {
    // This is the list of languages your application supports
    supportedLngs: [
        'en',
        'nl',
        'fr',
        'de',
        'ar',
        'es',
        'pt',
        'it',
        'tr',
        'ja',
        'ko',
        'zh',
        'ru',
        'hi',
        'bn',
        'pa',
        'gu',
        'ta',
        'te',
        'ml',
        'mr',
        'kn',
        'or',
        'as',
        'sd',
        'ur',
        'ne',
        'si',
        'ps',
        'fa',
        'ku',
    ],
    // This is the language you want to use in case
    // if the user language is not in the supportedLngs
    fallbackLng: 'en',
    // The default namespace of i18next is "translation", but you can customize it here
    defaultNS: 'shop',
    nsSeparator: false,
    keySeparator: false,
    resources,
} as const;
