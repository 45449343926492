export const nl = {
    shop: {
        'public.shop.pointsCurrency': 'Punten',
    },
    referral: {
        'public.referral.refereeMessage': '',
        'public.referral.shareMessage': '',
    },
    promotion: {
        'public.promotion.title': '',
        'public.promotion.description': '',
    },
    'earn-rule': {
        'public.earnRule.title': 'Verdien {{pointsCurrency}}',
        'public.earnRule.subtitle': 'Zo kun je {{pointsCurrency}} verdienen',
        'public.earnRule.type.CREATE_ACCOUNT.title': 'Maak een account aan en verdien {{points}} {{pointsCurrency}}',
        'public.earnRule.type.PLACE_ORDER.title': 'Voor elke 1€ die je uitgeeft, verdien je 1 {{pointsCurrency}}',
        'public.earnRule.type.CELEBRATE_BIRTHDAY.title': 'Op je verjaardag verdien je {{points}} {{pointsCurrency}}',
        'public.earnRule.type.FOLLOW_ON_INSTAGRAM.title':
            'Volg ons op Instagram {{handle}} en verdien {{points}} {{pointsCurrency}}',
        'public.earnRule.type.LIKE_ON_FACEBOOK.title':
            'Like onze pagina op Facebook {{handle}} en verdien {{points}} {{pointsCurrency}}',
        'public.earnRule.type.SHARE_ON_FACEBOOK.title':
            'Deel onze winkel op Facebook {{handle}} en verdien {{points}} {{pointsCurrency}}',
        'public.earnRule.type.FOLLOW_ON_TWITTER.title': 'Volg ons op X {{handle}} en verdien {{points}} {{pointsCurrency}}',
        'public.earnRule.type.FOLLOW_ON_TIKTOK.title': 'Volg ons op TikTok {{handle}} en verdien {{points}} {{pointsCurrency}}',
        'public.earnRule.type.SIGNUP_NEWSLETTER.title':
            'Abonneer je op onze nieuwsbrief en verdien {{points}} {{pointsCurrency}}',
        'public.earnRule.type.ADD_REVIEW.title': 'Voeg een recensie toe en verdien {{points}} {{pointsCurrency}}',
    },
    'spend-rule': {
        'public.spendRule.type.FREE_SHIPPING.title': 'Krijg gratis verzending op je volgende bestelling',
        'public.spendRule.type.FREE_SHIPPING.fulfillment': 'Gratis verzending',
        'public.spendRule.type.FREE_SHIPPING.instructions': 'Gebruik deze kortingscode bij je volgende bestelling!',
        'public.spendRule.type.PRODUCT_DISCOUNT.title': 'Wissel {{points}} {{pointsCurrency}} in en krijg {{discount}} korting',
        'public.spendRule.type.PRODUCT_DISCOUNT.fulfillment': '{{discount}} korting coupon',
        'public.spendRule.type.PRODUCT_DISCOUNT.instructions': 'Gebruik deze kortingscode bij je volgende bestelling!',
        'public.spendRule.type.ORDER_DISCOUNT.title':
            'Wissel {{points}} {{pointsCurrency}} in en krijg {{discount}} korting op je bestelling',
        'public.spendRule.type.ORDER_DISCOUNT.fulfillment': '{{discount}} korting coupon',
        'public.spendRule.type.ORDER_DISCOUNT.instructions': 'Gebruik deze kortingscode bij je volgende bestelling!',
        'public.spendRule.type.POS_DISCOUNT.title':
            'Wissel {{points}} {{pointsCurrency}} in en ontvang {{discount}} korting op je bestelling',
        'public.spendRule.type.POS_DISCOUNT.fulfillment': '{{discount}} kortingsbon',
        'public.spendRule.type.POS_DISCOUNT.instructions': 'Korting bij verkooppunt, te gebruiken in de winkel!',
        'public.spendRule.type.FREE_PRODUCT.title': 'Wissel {{points}} {{pointsCurrency}} in en ontvang een gratis product',
        'public.spendRule.type.FREE_PRODUCT.fulfillment': 'Gratis product',
        'public.spendRule.type.FREE_PRODUCT.instructions': 'Gebruik deze kortingscode bij je volgende bestelling!',
        'public.spendRule.type.GIFT_CARD.title':
            'Wissel {{points}} {{pointsCurrency}} in en ontvang een {{discount}} cadeaukaart',
        'public.spendRule.type.GIFT_CARD.fulfillment': '{{discount}} cadeaukaart',
        'public.spendRule.type.GIFT_CARD.instructions': 'Controleer uw e-mail, we hebben u de cadeaukaartcode toegestuurd!',
        'public.spendRule.type.STORE_CREDIT.title':
            'Wissel {{points}} {{pointsCurrency}} in en ontvang {{discount}} winkelkrediet',
        'public.spendRule.type.STORE_CREDIT.fulfillment': '{{discount}} winkelkrediet',
        'public.spendRule.type.STORE_CREDIT.instructions':
            'Gebruik dit winkelkrediet online bij het afrekenen van je volgende bestelling!',
    },
    branding: {
        'public.widget.authenticated.header.title': 'Loyaliteit & Beloningen',
        'public.widget.unauthenticated.header.title': 'Hey {{customerName}}!',
        'public.widget.becomeAMember.title': 'Word lid',
        'public.widget.becomeAMember.subtitle':
            'Sluit je aan bij ons loyaliteitsprogramma en begin vandaag met het verdienen van beloningen.',
        'public.widget.becomeAMember.joinProgram': 'Word lid',
        'public.widget.becomeAMember.alreadyAMember': 'Al lid ?',
        'public.widget.becomeAMember.login': 'Inloggen',
        'public.widget.balanceCard.points': '{{pointsCurrency}}',
        'public.widget.balanceCard.referrals': 'Aanbevelingen',
        'public.widget.balanceCard.tier': 'Niveau',
        'public.widget.spendAndEarnCard.title': 'Beloningen',
        'public.widget.spendAndEarnCard.subtitle':
            'Verzamel {{pointsCurrency}}, ontgrendel beloningen - winkelen wordt nog leuker!',
        'public.widget.spendAndEarnCard.earn.title': 'Manieren om {{pointsCurrency}} te verdienen',
        'public.widget.spendAndEarnCard.spend.title': 'Manieren om {{pointsCurrency}} te besteden',
        'public.widget.spendAndEarnCard.vouchers.title': 'Bekijk je coupons',
        'public.widget.referralPage.title': 'Beveel een vriend aan',
        'public.widget.activityCard.title': 'Activiteit',
        'public.widget.referralCard.title': 'Nodig je vrienden uit en verdien beloningen',
        'public.widget.tiersPage.title': 'Niveaus',
        'public.widget.tiersPage.subtitle': 'Ontgrendel meer beloningen naarmate je niveaus oploopt',
        'public.widget.vouchersPage.title': 'Je coupons',
        'public.widget.vouchersPage.subtitle': 'Bekijk je beschikbare coupons',
        'public.widget.rewardsPage.title': 'Beloningen',
        'public.widget.rewardsPage.subtitle': 'Zo kun je je zuurverdiende {{pointsCurrency}} besteden',
        'public.widget.earnPointsPage.title': '{{pointsCurrency}} verdienen',
        'public.widget.earnPointsPage.subtitle': 'Zo kun je {{pointsCurrency}} verdienen',
        'public.widget.activityPage.title': 'Activiteit',
        'public.widget.activityPage.subtitle': 'Bekijk je recente activiteit',
        'public.widget.activityPage.noActivity.title': 'Nog geen activiteit',
        'public.widget.rewardsCard.title': 'Beloningen',
        'public.widget.rewardsCard.subtitle': 'Verzamel {{pointsCurrency}}, ontgrendel beloningen - winkelen wordt nog leuker!',
        // Full page
        'public.fullPage.unauthenticatedHeroTitle':
            'Word lid van het loyaliteitsprogramma en ontvang beloningen tijdens het winkelen. Je krijgt {{points}} {{pointsCurrency}} voor het aanmelden',
        'public.fullPage.authenticatedHeroTitle': 'Je hebt {{points}} {{pointsCurrency}}',
        'public.fullPage.hero.joinProgram': 'Word lid',
        'public.fullPage.hero.login': 'Inloggen',
        'public.fullPage.navigation.earnPoints': '{{pointsCurrency}} verdienen',
        'public.fullPage.navigation.rewards': 'Beloningen',
        'public.fullPage.navigation.tiers': 'Niveaus',
        'public.fullPage.navigation.referralProgram': 'Beveel een vriend aan',
        'public.fullPage.navigation.activity': 'Jouw activiteit',
        'public.fullPage.navigation.vouchers': 'Je coupons',
        'public.fullPage.navigation.storeCredit': 'Winkelkrediet {{credit}}',
        'public.fullPage.referralCard.title': 'Beveel een vriend aan',
        // Referral Modal
        'public.referrals.title': 'We hebben een korting voor jou',
        'public.referrals.subtitle': 'Krijg 50% korting op je eerste aankoop!',
        'public.referrals.label': 'Voer uw e-mailadres hieronder in en we sturen u de kortingscode.',
        'public.referrals.button': 'Korting krijgen',
    },
};
