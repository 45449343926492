import { useLocation, useMatches } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { useEffect } from 'react';

export function initClientMonitoring() {
    Sentry.init({
        dsn: window.LeatEnv.sentryDsn,
        environment: window.LeatEnv.environment,
        beforeSend(event) {
            if (event.request?.url) {
                const url = new URL(event.request.url);
                if (url.protocol === 'chrome-extension:' || url.protocol === 'moz-extension:') {
                    // This error is from a browser extension, ignore it
                    return null;
                }
            }
            return event;
        },
        integrations: [
            Sentry.browserTracingIntegration({
                useEffect,
                useLocation,
                useMatches,
            }),
            // Replay is only available in the client
            Sentry.replayIntegration({
                maskAllInputs: false,
                maskAllText: false,
                maskFn: (text) => text,
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: window.LeatEnv.environment === 'production' ? 1.0 : 0,
    });
}
